body {
  background: url("../img/dark-material-bg.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  line-height: 22px;
  color: #fff;
}

h1 {
  line-height: 35px;
}

.MuiInputBase-input {
  color: white !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: white !important;
}


.MuiFormLabel-root.Mui-error {
  color: #f44336 !important;
}

.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: red !important;
}

.MuiInputLabel-outlined {
  color: white !important;
}
